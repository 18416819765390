<template lang="pug">
	extends ./table.pug

	block tableTH
		template(v-slot:cell(date)='data')
			span.b3(v-if="data.item?.date") {{ data.item.date | dateDDMMMYYYYDots }}

		template(v-slot:cell(number)='data')
			span.b3(v-if="data.item?.id") {{ data.item.id }}

		template(v-slot:cell(follower)='data')
			p.table-events__follower
				user-short-data(v-if='data.item.user && data.item.user.id' :item='data.item.user')
				span.text-warning(v-else) {{ $t('user.unknow') }}
			p.mb-0.text-sub.text-grey.text-nowrap {{ data.item.email }}

		template(v-slot:cell(tariff)='data')
			span.b3(v-if="data.item?.plan") {{ data.item.plan.title }}

		template(v-slot:cell(mrr)='data')
			span.b3(v-if="data.item?.mrr" v-html="data.item.mrr.priceWithCurrency()")

		template(v-slot:cell(status)='data')
			.border-rounded.table-events__status(v-if="id == 'recoveryEvents'" :class="[{'is-restored' : data.item?.status == 'recovered'}, {'is-not-restored' : data.item?.status == 'cancelled'}, {'is-on-recovery' : data.item?.status == 'waitingPayment'}]")
				span.b4.text-grey(v-if="data.item?.status == 'recovered'" v-html="$t('project.analytics.comebacker.successfulPayment')")
				span.b4.text-grey(v-if="data.item?.status == 'cancelled'" v-html="$t('project.analytics.comebacker.cancelledByUser')")
				span.b4.text-grey(v-if="data.item?.status == 'onRecovery'" v-html="$t('project.analytics.comebacker.waitingForPayment')")
				span.b4.text-primary(v-if="data.item?.status == 'recovered' || data.item?.status == 'waitingPayment'") {{ data.item.comebacker.title }}

			.border-rounded.table-events__status(v-if="id == 'recoveryPaymentsEvents'"  :class="[{'is-restored' : data.item?.status == 'recovered'}, {'is-not-restored' : data.item?.status == 'lost'}, {'is-on-recovery' : data.item?.status == 'onRecovery'}]")
				span.b4.text-grey(v-if="data.item?.status == 'recovered'" v-html="$t('project.analytics.error-processing.is-restored')")
				span.b4.text-grey(v-if="data.item?.status == 'lost'" v-html="$t('project.analytics.error-processing.is-not-restored')")
				span.b4.text-grey(v-if="data.item?.status == 'onRecovery' && (data.item?.errorGroup == 'CRITICAL' || data.item?.errorGroup == 'DECLINE_SOFT' || data.item?.errorGroup == 'DECLINE_LIMIT' || data.item?.errorGroup == 'NO_FUNDS' || data.item?.errorGroup == 'UNKNOWN')" v-html="$t('project.analytics.error-processing.is-critical')")
				span.b4.text-grey(v-if="data.item?.status == 'onRecovery'  && (data.item?.errorGroup == 'DECLINE_HARD' || data.item?.errorGroup == 'FRAUD')" v-html="$t('project.analytics.error-processing.is-fraud')")

		template(v-slot:head(status)="data")
			.d-flex.align-items-center
				span {{ $t('project.status') }}
				svg.ml-10.cursor-pointer(:id="!isMobile ? `error-processing-statuses-${id}` : ''" @click="isMobile ? $bvModal.show(`error-processing-statuses-${id}`) : ''" width='22' height='22' viewbox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg')
					circle(cx='11' cy='11' r='11' :fill='isDarkColor ? "#27282A" : "white"')
					path(d='M10.67 12.2467C10.67 12.0609 10.6798 11.8898 10.6993 11.7333C10.7287 11.5671 10.7824 11.4058 10.8607 11.2493C10.9487 11.0831 11.066 10.912 11.2127 10.736C11.3691 10.56 11.5744 10.3644 11.8287 10.1493C12.2393 9.79733 12.5522 9.47956 12.7673 9.196C12.9824 8.91244 13.09 8.60933 13.09 8.28667C13.09 7.81733 12.9336 7.43111 12.6207 7.128C12.3078 6.82489 11.8776 6.67333 11.33 6.67333C10.7824 6.67333 10.3522 6.82489 10.0393 7.128C9.72644 7.43111 9.57 7.81733 9.57 8.28667H8.25C8.25 7.88578 8.31844 7.51911 8.45533 7.18667C8.602 6.84444 8.80733 6.55111 9.07133 6.30667C9.34511 6.05244 9.67267 5.85689 10.054 5.72C10.4353 5.57333 10.8607 5.5 11.33 5.5C11.7993 5.5 12.2247 5.57333 12.606 5.72C12.9873 5.85689 13.31 6.05244 13.574 6.30667C13.8478 6.55111 14.0531 6.84444 14.19 7.18667C14.3367 7.51911 14.41 7.88578 14.41 8.28667C14.41 8.56044 14.366 8.81956 14.278 9.064C14.19 9.30844 14.0678 9.54311 13.9113 9.768C13.7647 9.98311 13.5936 10.1933 13.398 10.3987C13.2024 10.5942 13.002 10.78 12.7967 10.956C12.6304 11.0929 12.4936 11.2249 12.386 11.352C12.2784 11.4693 12.1904 11.5867 12.122 11.704C12.0633 11.8116 12.0193 11.924 11.99 12.0413C11.9704 12.1489 11.9607 12.2662 11.9607 12.3933V12.6867H10.67V12.2467ZM11.33 16.06C11.0464 16.06 10.802 15.9622 10.5967 15.7667C10.4011 15.5613 10.3033 15.3169 10.3033 15.0333C10.3033 14.74 10.4011 14.4956 10.5967 14.3C10.802 14.1044 11.0464 14.0067 11.33 14.0067C11.6136 14.0067 11.8531 14.1044 12.0487 14.3C12.254 14.4956 12.3567 14.74 12.3567 15.0333C12.3567 15.3169 12.254 15.5613 12.0487 15.7667C11.8531 15.9622 11.6136 16.06 11.33 16.06Z' fill='#52A7F9')

				modal-tooltip(
					:id="`error-processing-statuses-${id}`"
					:classTooltip="'error-processing-tooltip'"
					:title="$t('project.status')"
					:text="`<span class='table-events__tooltip is-orange'>${$t('plan.nextPaymentInSupport')}</span><span class='table-events__tooltip is-green'> ${$t('plan.recovered')} </span><span class='table-events__tooltip is-grey'> ${$t('plan.notRestored')} </span>`"
					:textMobile="`<div class='tooltip-wrap'><span class='table-events__tooltip is-orange'>${$t('plan.nextPaymentInSupport')}</span><span class='table-events__tooltip is-green'> ${$t('plan.recovered')} </span><span class='table-events__tooltip is-grey'> ${$t('plan.notRestored')} </span></div>`"
				)
</template>

<script>
import { mapGetters } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';
import TablePagination from './TablePagination';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import UserShortData from "@/components/Common/UserShortData.vue";

export default {
	name: 'TableEvents',
	mixins: [
		TableMixin
	],
	props: {
		id: {
			type: String,
			default: ''
		},
		items: {
			type: Array,
			default: () => ([])
		},
		totalItems: {
			type: Number,
			default: null
		},
	},
	components : {
		UserShortData,
		TableSearch,
		TablePagination,
		TableShowMore,
		ModalTooltip
	},
	data() {
		return {
			sortBy: 'createdAt',
			noLocalSorting: true,
			isbusy: false,
			fields: [
				{
					key: 'date',
					label: this.$t('h1.date'),
				},
				{
					key: 'number',
					label: this.$t('project.id'),
				},
				{
					key: 'follower',
					label: this.$t('project.follower'),
				},
				{
					key: 'tariff',
					label : this.$t('plan.title'),
				},
				{
					key: 'mrr',
					label: 'MRR',
				},
				{
					key: 'status',
					label: '',
				}
			],
		}
	},
	mounted() {
	},
	computed: {
		...mapGetters({
			//totalItems: 'project/totalMetrics',
		}),
		/*items() {
			this.isbusy = false;
			if (!this.list || !this.list.items) return [];

			return this.list.items;
		},*/
	},
	methods: {
        /*...mapActions({
			getRecoveryEvents: 'comebackers/getRecoveryEvents',
			getRecoveryPaymentsEvents: 'comebackers/getRecoveryPaymentsEvents'
		}),*/
		/*doSorting() {
			let items = _.cloneDeep(this.items);
			items = _.filter(items, s => !s.parent)
			items.forEach(x => {
				x.isExtended = false;

				if (x.subCategory) {
					x.subCategory.forEach(y => {
						y._checked = false
					})
				}
			});

			items = items.sort((a, b) => {
				if ( b.categoryType == 'TOTAL' ) return 1;

				var A = a[this.sortBy];
				var B = b[this.sortBy];

				if (this.sortDesc)
					return A > B && !a.parent ? -1 : 1;

				return A > B & !a.parent ? 1 : -1;
			});

			//this.$store.commit('project/setMetricsItems', items);

			return items;
		},*/
		localSorting(ctx) {
			this.sortBy = ctx.sortBy;
			this.sortDesc = ctx.sortDesc;
			//this.doSorting();
		}
	}
}
</script>

<style lang='scss'>
@import "~@/assets/css/sass/_mixins.scss";

.table-events {
	&__follower {
		margin-bottom: 8px;
		margin-top: -5px;
	}

	&__status {
		padding: 10px;
		background-color: var(--bg-back);
		max-width: 197px;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -5.5px;
			left: -7.5px;
			width: 21px;
			height: 21px;
			border: 5px solid var(--foreground-color);
			border-radius: 50%;
		}

		&.is-on-recovery {
			&:before {
				background-color: var(--brand-orange);
			}
		}

		&.is-restored {
			&:before {
				background-color: var(--brand-success);
			}
		}

		&.is-not-restored {
			&:before {
				background-color: var(--grey-text-color);
			}
		}
	}

	&__tooltip {
		display: block;
		position: relative;
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
			left: -21px;
			width: 11px;
			height: 11px;
			border-radius: 50%;
		}

		&.is-orange {
			color: var(--brand-orange);

			&:before {
				background-color: var(--brand-orange);
			}
		}

		&.is-green {
			color: var(--brand-success);

			&:before {
				background-color: var(--brand-success);
			}
		}

		&.is-grey {
			color: var(--grey-text-color);

			&:before {
				background-color: var(--grey-text-color);
			}
		}
	}
}

</style>
