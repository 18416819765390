<template lang='pug'>
	.on-recovery
		h3.b1 {{ $t('plan.nextPaymentInSupport') }}
		.mt-10.mb-10
			span.text-bold.text-primary.mr-10.analytics-mrr__count {{ total }}
			span.b3.text-grey {{ declOfSubscriptions(total) }}
		span.b3.text-grey {{ percent }}% {{ $t('project.analytics.ofAllSubscriptions') }}

		.border-rounded.analytics-mrr__inner
			.d-flex.align-items-center.mb-10
				b.b3.text-grey.mr-10 MRR
			.d-flex.analytics-mrr__list
				span.analytics-mrr__list-item(v-html="mrrRub")
				span.analytics-mrr__list-item(v-html="mrrDollar")
				span.analytics-mrr__list-item(v-html="mrrEuro")
</template>

<script>
export default {
	name: 'OnRecovery',
	props: {
		total: {
			type: [String, Number],
			default: ''
		},
		percent: {
			type: [String, Number],
			default: ''
		},
		mrrRub: {
			type: [String, Number],
			default: ''
		},
		mrrDollar: {
			type: [String, Number],
			default: ''
		},
		mrrEuro: {
			type: [String, Number],
			default: ''
		}
	},
	methods: {
		declOfNum(n, text_arr) {
			let num = Math.abs(n) % 100;
			let n1 = num % 10;

			if (num > 10 && num < 20) {
				return text_arr[2];
			}
			if (n1 > 1 && n1 < 5) {
				return text_arr[1];
			}
			if (n1 == 1) {
				return text_arr[0];
			}
			return text_arr[2];
		},
		declOfSubscriptions(num) {
			return this.declOfNum(num, [this.$t('promotions.declOfSubscriptions[0]'), this.$t('promotions.declOfSubscriptions[1]'), this.$t('promotions.declOfSubscriptions[2]')]);
		}
	}
}
</script>
