<template lang="pug">
	.recovered
		h3.b1 {{ $t('plan.recovered') }}
		span.b3.text-grey.mt-10.d-block.analytics-mrr__all-time {{ $t('income.allTime') }}

		.analytics-mrr__center
			.border-rounded.analytics-mrr__block
				span.b1.text-bold.d-block.analytics-mrr__summa(v-html="`🇷🇺 ${rub}`")
				span.b1.text-bold.d-block.analytics-mrr__summa(v-html="`🌍 ${dollar}`")
			.btn.border-rounded.analytics-mrr__improve(v-if="button" @click="$router.push({name: 'project_plans', query: {tabPromotions: 3}})") {{ button }}

		.analytics-mrr__wrap
			.analytics-mrr__pie-chart
				.analytics-mrr__rr
					span.b4.text-grey.d-block RR
					span.h3.text-bold.d-block.analytics-mrr__rr-percent {{ rr }}%
				apexchart(type="donut" height="266" :options="chartOptions" :series="series")

			.analytics-mrr__right
				.border-rounded.analytics-mrr__block
					span.b1.text-bold.d-block.analytics-mrr__summa(v-html="`🇷🇺 ${rub}`")
					span.b1.text-bold.d-block.analytics-mrr__summa(v-html="`🌍 ${dollar}`")
				.btn.border-rounded.analytics-mrr__improve(v-if="button" @click="$router.push({name: 'project_plans', query: {tabPromotions: 3}})") {{ button }}

		modal-tooltip(
			:id="`recovered-${id}`"
			:title="titleRecovered"
			:classTooltip="'recovered-tooltip'"
			:textMobile='`<span class="text-main">${this.$t("income.allTime2")} </span><span class="text-primary text-bold at-percent">${amountOfPercent}%.</span></span>${additionalSign}`'
		)
</template>

<script>
import { DEVICE } from '@/utils/device';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
	name: 'Recovered',
	components: {
		ModalTooltip
	},
	props: {
		id: {
			type: [String, Number],
			default: ''
		},
		outflow: {
			type: [String, Number],
			default: ''
		},
		recovered: {
			type: [String, Number],
			default: ''
		},
		rr: {
			type: [String, Number],
			default: ''
		},
		rub: {
			type: [String, Number],
			default: ''
		},
		dollar: {
			type: [String, Number],
			default: ''
		},
		graphicsColors: {
			type: Array,
			default: () => ['#52A7F9', 'rgba(82, 167, 249, 0.2)']
		},
		button: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			titleRecovered: '',
			amountOfPercent: '',
			additionalSign: '',
			series: [+this.rr, 100 - +this.rr],
			chartOptions: {
				chart: {
					type: 'donut',
					events: {
						dataPointSelection: (event, chartContext, config) => {
							if(DEVICE.isMobile()) {
								if(config.dataPointIndex == 0) {
									this.titleRecovered = `<svg width="32" height="32" viewBox="0 0 32 32" class="at-icon mr-10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16"/><path d="M20.5523 14.1775L15.9998 9.625L11.4473 14.1775" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 22.3745V9.75195" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>${this.$t('plan.efficiency')}`;
									this.amountOfPercent = this.series[0];
									this.$bvModal.show(`recovered-${this.id}`);
								} else if(config.dataPointIndex == 1) {
									this.titleRecovered = `<svg width="32" height="32" viewBox="0 0 32 32" class="at-icon mr-10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16"/><path d="M20.5523 17.8223L15.9998 22.3748L11.4473 17.8223" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 9.625V22.2475" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>${this.$t('plan.outflow')}`;
									this.amountOfPercent = this.series[1];
									this.additionalSign = `<span class="b4 text-grey d-block at-sign">${this.$t("plan.lowerChurnBetter")}</span>`;
									this.$bvModal.show(`recovered-${this.id}`);
								}
							}
						}
					}
				},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '76%'
						}
					}
				},
				legend: {
					position: 'bottom',
					inverseOrder: true,
					onItemHover: {
						highlightDataSeries: false
					},
					onItemClick: {
						toggleDataSeries: false
					}
				},
				dataLabels: {
					enabled: false
				},
				colors: this.graphicsColors,
				labels: [this.$t('plan.recovered'), this.$t('plan.outflow')],
				stroke: {
					width: 4
				},
				tooltip: {
					enabled: !DEVICE.isMobile(),
					style: {
						fontSize: '14px',
						fontFamily: 'Golos, Nunito, sans-serif'
					},
					y: {
						title: {
							formatter: (seriesName, { seriesIndex, dataPointIndex, w }) => {
								if(seriesName == this.$t('plan.recovered')) {
									return `<span class="d-flex align-items-center"><svg width="32" height="32" viewBox="0 0 32 32" class="at-icon" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16"/><path d="M20.5523 14.1775L15.9998 9.625L11.4473 14.1775" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 22.3745V9.75195" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg><span class="text-bold text-main at-title">${this.$t('plan.efficiency')}</span> <span class="text-main">${this.$t('income.allTime2')} </span><span class='text-primary text-bold at-percent'>${this.series[seriesIndex]}%.</span></span>`;
								} else {
									return `<span class="d-flex align-items-center"><svg width="32" height="32" viewBox="0 0 32 32" class="at-icon" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16"/><path d="M20.5523 17.8223L15.9998 22.3748L11.4473 17.8223" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 9.625V22.2475" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg><span class="text-bold text-main at-title">${this.$t('plan.outflow')}</span> <span class="text-main">${this.$t('income.allTime2')} </span><span class='text-primary text-bold at-percent'>${this.series[seriesIndex]}%.</span></span><span class="b4 text-grey d-block at-sign">${this.$t('plan.lowerChurnBetter')}</span>`;
								}
							}
						}
					}
				}
			}
		}
	}
}

</script>
