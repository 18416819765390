<template lang="pug">
	.card-basic.restored-income

		.border-rounded.mb-10.restored-income__info
			svg.flex-shrink-0(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
				path(d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				path(d='M11.9941 8H12.0031' stroke='#52A7F9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
				path(d='M11.9941 12V17' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
			span.b3.ml-10 {{ $t('project.analytics.infoOnRestoredIncome') }}

		table-restored-income.vuetable(:hideSearch="true" :hidePerPage="true" :items="items")

</template>

<script>
import TableRestoredIncome from '@/components/Tables/RestoredIncomeTable';

export default {
	name: 'RestoredIncome',
	components: {
		TableRestoredIncome
	},
	props: {
		items: {
			type: Array,
			default: () => ([])
		},
	}
}

</script>

<style lang='scss'>
@import "~@/assets/css/sass/_mixins.scss";

.restored-income {
	margin-top: 20px;

	@include respond-below(xs) {
		padding: 15px 17px;
	}

	&__info {
		border: 1px solid var(--border-line);
		padding: 19px 19.5px;
		display: flex;
		align-items: center;

		@include respond-below(xs) {
			align-items: flex-start;
			padding: 14px 16.5px;
		}
	}
}

</style>
