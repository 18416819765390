<template lang="pug">

	extends ./table.pug

	block tableTH
		template(v-slot:head()="data")
			span(v-html="data.field.label")

		template(v-slot:cell(index0)='data')
			.td-line.simple-chart-legend-colored(:style='{background: "#"+data.item.color}' v-if='data.item.checked') &nbsp;
			.d-flex.align-items-center(:class='childClass(data.item)')
				i.cursor-pointer.ml-1.mr-4.fontello.text-primary.simple-icon-plus(v-if='data.item.hasChild' @click='extendRow(data.item)' :class='{"simple-icon-plus" : !data.item.isExtended, "simple-icon-minus" : data.item.isExtended}')
				i.cursor-pointer.ml-1.mr-4.fontello.text-primary.simple-icon-plus.invisible(v-else)
				.simple-chart-legend--item.simple-chart-legend-colored(:style='{"--color" : "#" + (data.item._checked ? data.item.color : "52A7F9")}')
					b-form-checkbox.checkbox-metric(v-model='data.item._checked' @change='checkingItem(data.item)')
						| {{ data.item.getTitle($t('project.analytics.sourcesLegend')) }}
		template(v-slot:cell(viewsCount)='data')
			span.b3 {{ data.item.viewsCount }}
		template(v-slot:cell(subsCount)='data')
			span.b3 {{ data.item.subsCount }}
		template(v-slot:cell(conversion)='data')
			span.b3 {{ data.item.conversion }}%
		template(v-slot:cell(recurrentCount)='data')
			span.b3 {{ data.item.recurrentCount }}
		template(v-slot:cell(totalIncomeOther)='data')
			span.b3.text-nowrap(v-html="`${data.item.totalIncomeOther.toLocaleString('ru')} &#8381;`")
		template(v-slot:cell(totalIncomeWorld2ru)='data')
			span.b3.text-nowrap(v-html="`${data.item.totalIncomeWorld2ru.toFixed(2).replace('.00', '')} &#36; `")

		template(v-slot:head(index0)="data")
			.d-flex.align-items-center(v-html="data.label")
</template>

<script>
import { mapGetters } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';
import TablePagination from './TablePagination';

export default {
	name: 'AnalyticsUTM',
	mixins: [
		TableMixin
	],
	props: {
		type: {
			type: String,
			default: null
		}
	},
	components 	: {
		TableSearch,
		TablePagination,
		TableShowMore
	},
	data () {
		return {
			sortBy: 'createdAt',
			noLocalSorting: true,
			fields: [],
			isbusy: false
		}
	},
	mounted() {
		this.getFields();
		this.trClass = this.rowClass;
	},
	computed: {
		...mapGetters({
			list: 'project/metricsUtm',
			totalItems: 'project/totalMetricsUtm'
		}),
		items() {
			this.isbusy = false;
			if (!this.list || !this.list.items) return [];

			return this.list.items;
		}
	},
	methods: {
		doSorting() {
			let items = _.cloneDeep(this.list.items);
			items = _.filter(items, s => !s.parent)
			items.forEach(x => {
				x.isExtended = false;

				if (x.children) {
					x.children.forEach(y => {
						y._checked = false
						y.isExtended = false;

						y.children.forEach(z => {
							z._checked = false
						})
					})
				}
			});

			items = items.sort((a, b) => {
				if ( b.key == 'TOTAL' ) return 1;

				var A = a[this.sortBy];
				var B = b[this.sortBy];

				if (this.sortDesc)
					return A > B && !a.parent ? -1 : 1;

				return A > B & !a.parent ? 1 : -1;
			});

			this.$store.commit('project/setMetricsUtmItems', items);

			return items;
		},
		localSorting(ctx) {
			this.sortBy = ctx.sortBy;
			this.sortDesc = ctx.sortDesc;
			this.doSorting();
			this.$emit('onChangeSubCategoriesUTM');
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return

			let cl = item.parent != null ? ["table-tr-children"] : [];

			return cl.join(" ");
		},
		childClass ( item ) {
			return item.parent ? "table-tr-children table-tr-children" + item.deep : '';
		},
		getFields () {
			//console.log(this.items[0], 'this.items[0]')

			this.fields = _.map(this.$t('project.analytics.sourcesTableTh'), (th, i) => {
				return {
					key 		: i == 0 ? 'index' + i : th.name,
					label 		: th.title,
					sortable	: i != 0,
					tdClass 	: 'align-middle position-relative' + (i == 0 ? ' text-nowrap' : ''),
				}
			});
			this.fields[0].label = `UTM Source, UTM Medium, UTM Campaign,<br>UTM Content, UTM Term`;
		},
		extendRow(item) {
			this.$store.commit('project/metricUtmExpand', item);
		},
		checkingItem(item) {
			if (item.parent != null) {
				let p = _.find(this.items, i => i.name == item.parent );
				if (p) p.checked = false;
			}
			this.$emit('onChangeSubCategoriesUTM')
		}
	}
}
</script>

<style lang='scss' scoped>
	.fontello {
		font-size: 20px;
	}

	.table {
		th {
			.fontello-icon-question {
				&:before {
					background: transparent;
					border-radius: 50%;
				}
			}
		}
	}

	.table-tr-children,
	.table-tr-children0 {
		--p : 20px;

		padding-left: var(--p);
		.td-line {
			left: calc(var(--p) + 2px) !important;
		}

		&.table-tr-children1 { --p: 42px; }
		&.table-tr-children2 { --p: 60px; }
		&.table-tr-children3 { --p: 80px; }
		&.table-tr-children4 { --p: 100px; }
	}

	@media (max-width: 767.98px) {
		.cursor-pointer.mr-4 {
			margin-right: 16px !important;
		}
	}
</style>
